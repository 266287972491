var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//@ts-nocheck
import { LitElement, css, html } from 'lit';
import BackArrowElement from './back-arrow.js';
import { go } from '../router/router-base.js';
import routes from '../router/routes.js';
import getRoutes from '../router/routes.js';
import AppEvents from '../shared/app-events.js';
import { logoutIcon, homeIcon } from '../assets/icons.js';
import { getCurrentUser } from '../utility/auth-service.js';
import { LevelViewer } from './level-viewer.js';
class AppHeaderElement extends LitElement {
    constructor() {
        super();
        this.user = null;
        this.profilePic = '';
        this.currentLocation = window.location.pathname;
        this.hideButton = this.currentLocation === '/dashboard';
    }
    connectedCallback() {
        const _super = Object.create(null, {
            connectedCallback: { get: () => super.connectedCallback }
        });
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            _super.connectedCallback.call(this);
            this.user = yield getCurrentUser();
            this.profilePic = ((_b = (_a = this.user) === null || _a === void 0 ? void 0 : _a.additionalData) === null || _b === void 0 ? void 0 : _b.profilePic) || '';
            window.addEventListener('update-header', () => __awaiter(this, void 0, void 0, function* () {
                var _c, _d;
                this.user = yield getCurrentUser();
                this.profilePic = ((_d = (_c = this.user) === null || _c === void 0 ? void 0 : _c.additionalData) === null || _d === void 0 ? void 0 : _d.profilePic) || '';
                this.requestUpdate();
            }));
            this.requestUpdate();
        });
    }
    render() {
        var _a, _b;
        return html `
      <div class="header">
        <div class="header-left" @click=${() => go(routes.DASHBOARD.path)}>
          <h1>The Color Game</h1>
          ${((_b = (_a = this.user) === null || _a === void 0 ? void 0 : _a.additionalData) === null || _b === void 0 ? void 0 : _b.level) ? html `<level-viewer></level-viewer>` : ''}
        </div>
        ${this.profilePic
            ? html ` <div class="profile-pic" style="background-image: url('${this.profilePic}')"></div> `
            : ''}
      </div>
    `;
    }
}
AppHeaderElement.properties = {
    user: { type: Object },
    profilePic: { type: String },
};
AppHeaderElement.styles = css `
    svg {
      scale: 4;
      margin-left: 40px;
    }
    .header {
      display: flex;
      justify-content: left;
      align-items: center;
      align-content: center;
      height: 64px;
      width: 100vw;
      padding-left: 16px;
      margin-bottom: 100px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      background-color: #f2efe5;
      font-family: 'Arial';
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      gap: 16px;
      margin: 0;
    }

    .header-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .header-left > h1 {
      margin: 0;
      font-family: 'Arial';
      color: #515151;
      font-size: 24px;
    }
    .header-left > level-viewer {
      margin: 0;
    }

    .header > h1 {
      margin: 0;
      font-family: 'Arial';
      color: #515151;
      font-size: 24px;
    }
    .rainbow-child {
      height: 64px;
      width: 16px;
    }
    svg {
      cursor: pointer;
      height: 32px;
      width: 32px;
    }
    .hide {
      display: none;
    }
    .profile-pic {
      position: absolute;
      right: 32px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #ccc;
      background-size: cover;
      background-position: center;
      border: 4px solid grey;
      cursor: pointer;
    }
  `;
customElements.define('app-header', AppHeaderElement);
export default AppHeaderElement;

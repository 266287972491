var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//@ts-nocheck
import { get, getDatabase } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { ref, set, onValue } from 'firebase/database';
import { html, css } from 'lit';
import { generateRandomColorScheme, getGoalColor } from './color-db.js';
import { generateBotScores } from './leaderboard-service.js';
/**
 * @param {Number} value
 */
export function setValue(value) {
    const database = getDatabase();
    const reference = ref(database, '/my_key');
    set(reference, {
        key: value,
    });
}
/**
 * @param {{ getMonth: () => number; getDate: () => any; getFullYear: () => any; }} date
 */
export function formatDate(date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear());
    return month + day + year;
}
export function getColor() {
    const database = getDatabase();
    const date = formatDate(new Date());
    const reference = ref(database, `/daily_color/${date}`);
    onValue(reference, (snapshot) => {
        const data = snapshot.val();
        const blue = data === null || data === void 0 ? void 0 : data.blue;
        const green = data === null || data === void 0 ? void 0 : data.green;
        const red = data === null || data === void 0 ? void 0 : data.red;
        const name = data === null || data === void 0 ? void 0 : data.name;
    });
}
/**
 * @param {number} red
 * @param {number} green
 * @param {number} blue
 * @param {string} name
 * @param {number} date
 */
export function setColor(red, green, blue, name, date) {
    const database = getDatabase();
    const reference = ref(database, `/daily_color/${date}`);
    set(reference, {
        red,
        green,
        blue,
        name,
    });
}
export function autoSetColor() {
    return __awaiter(this, void 0, void 0, function* () {
        getColor();
        const todaysColor = yield getGoalColor();
        if (isNaN(todaysColor.red)) {
            const today = formatDate(new Date());
            const database = getDatabase();
            const colorRef = ref(database, `/daily_color/${today}`);
            const colorData = yield generateRandomColorScheme();
            const newColor = yield generateRandomColorScheme();
            setColor(newColor.red, newColor.green, newColor.blue, newColor.name, today);
            generateBotScores();
        }
        else {
            return;
        }
    });
}
/**
 * @param {string} userId
 * @param {string} username
 * @param {number} points
 * @param {string} profilePic
 * @param {string} friends
 * @param {number} level
 */
export function setUserData(userId, username, points, profilePic, friends, level = 1) {
    const database = getDatabase();
    const reference = ref(database, `/user_data/${userId}`);
    set(reference, {
        username,
        points,
        profilePic,
        friends,
        level,
    });
}
export function getUserData(userId) {
    const database = getDatabase();
    const reference = ref(database, `/user_data/${userId}`);
    return get(reference)
        .then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val();
        }
        else {
            return null;
        }
    })
        .catch((error) => {
        console.error('Error getting user data:', error);
        throw error;
    });
}
/**
 * Checks if a username already exists in the database
 * @param {string} username - The username to check
 * @returns {Promise<boolean>} - A promise that resolves to true if the username exists, false otherwise
 */
export function checkUsernameExists(username) {
    const database = getDatabase();
    const reference = ref(database, '/user_data');
    return get(reference)
        .then((snapshot) => {
        if (snapshot.exists()) {
            const userData = snapshot.val();
            return Object.values(userData).some((user) => user.username === username);
        }
        return false;
    })
        .catch((error) => {
        console.error('Error checking username:', error);
        throw error;
    });
}
/**
 * Increments a user's level by a specified amount
 * @param {string} userId - The user's ID
 * @param {number} increment - Amount to increase the level by (default: 1)
 * @returns {Promise<void>}
 */
export function incrementUserLevel(userId, increment = 1) {
    return __awaiter(this, void 0, void 0, function* () {
        const database = getDatabase();
        const reference = ref(database, `/user_data/${userId}`);
        try {
            const snapshot = yield get(reference);
            if (snapshot.exists()) {
                const userData = snapshot.val();
                const newLevel = (userData.level || 1) + increment;
                // Update only the level field
                yield set(reference, Object.assign(Object.assign({}, userData), { level: newLevel }));
            }
        }
        catch (error) {
            console.error('Error updating user level:', error);
            throw error;
        }
        setCurrentUser(yield getCurrentUser());
    });
}
